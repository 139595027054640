// // src/utils/api.js
// import axios from "axios";

// // Determine the base URL based on the environment
// // const baseURL =
// //   process.env.REACT_APP_NODE_ENV === "development"
// //     ? process.env.REACT_APP_API_BASE_URL_DEV
// //     : process.env.REACT_APP_API_BASE_URL_PROD;

// // const baseURL = '';
// // const REACT_APP_API_BASE_URL_DEV = window.location.hostname + "/api";
// // console.log(window.location.hostname);

// // const baseURL = REACT_APP_API_BASE_URL_DEV;
// const baseURL = "http://172.210.63.95:5000"
// // Create an instance of axios with the determined base URL
// const api = axios.create({
//   baseURL,
// });

// export default api;

import axios from 'axios';

const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
  }
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('adminToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', error);
    return Promise.reject(error);
  }
);

export default api;
