
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Grid,
  Paper,
  TextField,
  Button,
  LinearProgress,
  Container,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useAuth } from "../contexts/AuthContext";
import api from "../api";
import logo from "../assets/logo.jpg";

const initialProfileData = {
  additionalInfo: {
    otherInformation: "",
  },
  education: {
    graduationDegree: "",
    tenthBoardMarks: {
      marks: "",
      percentage: "",
    },
  },
  familyInfo: {
    fathersProfession: "",
    mothersProfession: "",
    parentsAnnualIncome: "",
  },
  futureGoals: {
    fiveYearVision: "",
  },
  interests: {
    hobbies: [],
  },
  personalInfo: {
    category: "",
    emailId: "",
    name: "",
    mobileNumber: "",
    religion: "",
  },
  professionalInfo: {
    postAppliedFor: "",
  },
  situationalJudgment: {
    lateWorkScenario: "",
  },
};

const ProfileCompletion = () => {
  const { token, user, setUser } = useAuth();
  const location = useLocation();
  const [profileData, setProfileData] = useState(initialProfileData);
  const [completionPercentage, setCompletionPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.profileData) {
      const incomingProfileData = location.state.profileData;
      const updatedProfileData = {
        ...initialProfileData,
        ...incomingProfileData,
      };

      // Ensure all fields have a defined value
      Object.keys(updatedProfileData).forEach((section) => {
        Object.keys(updatedProfileData[section]).forEach((field) => {
          if (
            updatedProfileData[section][field] === null ||
            updatedProfileData[section][field] === undefined
          ) {
            updatedProfileData[section][field] =
              initialProfileData[section][field];
          }
        });
      });

      setProfileData(updatedProfileData);
      console.log("Updated profile data:", updatedProfileData);
    } else {
      // Fetch user's profile data from the context
      setProfileData({
        ...profileData,
        personalInfo: {
          ...profileData.personalInfo,
          name: user.personalInfo.name || "",
          emailId: user.personalInfo.emailId || "",
          mobileNumber: user.personalInfo.mobileNumber || "",
        },
        education: {
          ...profileData.education,
          graduationDegree: user.education.graduationDegree || "",
          tenthBoardMarks: {
            ...profileData.education.tenthBoardMarks,
            marks: user.education.tenthBoardMarks.marks || "",
            percentage: user.education.tenthBoardMarks.percentage || "",
          },
        },
        familyInfo: {
          ...profileData.familyInfo,
          fathersProfession: user.familyInfo.fathersProfession || "",
          mothersProfession: user.familyInfo.mothersProfession || "",
          parentsAnnualIncome: user.familyInfo.parentsAnnualIncome || "",
        },
      });
    }
  }, [location.state, user]);

  useEffect(() => {
    const countFields = (obj) => {
      return Object.values(obj).reduce((acc, value) => {
        if (value && typeof value === "object" && !Array.isArray(value)) {
          return acc + countFields(value);
        }
        return acc + 1;
      }, 0);
    };

    const countFilledFields = (obj) => {
      return Object.values(obj).reduce((acc, value) => {
        if (value && typeof value === "object" && !Array.isArray(value)) {
          return acc + countFilledFields(value);
        }
        return acc + (value !== "" && value !== null && value !== "NA" ? 1 : 0);
      }, 0);
    };

    const totalFields = countFields(profileData);
    const filledFields = countFilledFields(profileData);

    setCompletionPercentage(
      totalFields > 0 ? (filledFields / totalFields) * 100 : 0
    );
  }, [profileData]);

  const handleChange = (section, field, value) => {
    setProfileData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [field]: value,
      },
    }));
  };

  const handleNestedChange = (section, subSection, field, value) => {
    setProfileData((prevData) => ({
      ...prevData,
      [section]: {
        ...prevData[section],
        [subSection]: {
          ...prevData[section][subSection],
          [field]: value,
        },
      },
    }));
  };

  const handleSubmit = async () => {
    const isEmpty = (obj) => {
      return Object.values(obj).some((value) => {
        if (typeof value === "object" && value !== null) {
          return isEmpty(value);
        }
        return (
          value === null ||
          value === "" ||
          (Array.isArray(value) && value.length === 0)
        );
      });
    };

    if (isEmpty(profileData)) {
      setSnackbarMessage("Please fill in all mandatory fields.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    // Validate email format
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    if (!emailRegex.test(profileData.personalInfo.emailId.toLowerCase())) {
      setSnackbarMessage("Please enter a valid email address.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    try {
      const response = await api.put("/profile", profileData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        console.log(response.data.message);
        setUser(response.data.profile); // Update the user's profile in the context
        navigate("/dashboard");
      } else {
        console.error("Error updating profile:", response.data.message);
        setSnackbarMessage("Error updating profile. Please try again.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error submitting profile data:", error);
      setSnackbarMessage("Error submitting profile data. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4, textAlign: "center" }}>
        <img
          src={logo}
          alt="Logo"
          style={{ width: "100px", height: "auto", marginBottom: 20 }}
        />
        <Typography variant="h4" gutterBottom>
          Complete Your Profile
        </Typography>
        <LinearProgress
          variant="determinate"
          value={completionPercentage}
          sx={{
            mb: 2,
            height: 10,
            borderRadius: 5,
            backgroundColor: "#e0e0e0",
          }}
        />
        <Typography variant="body2" gutterBottom>
          {`Profile Completion: ${Math.round(completionPercentage)}%`}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {/* Personal Information Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, backgroundColor: "#ffffff" }}>
            <Typography variant="h6" gutterBottom>
              Personal Information
            </Typography>
            <Grid container spacing={2}>
              {Object.entries(profileData.personalInfo).map(([key, value]) => (
                <Grid item xs={12} sm={6} key={key}>
                  <TextField
                    required={key !== "mobileNumber"}
                    fullWidth
                    label={key.charAt(0).toUpperCase() + key.slice(1)}
                    value={value || ""}
                    onChange={(e) =>
                      handleChange("personalInfo", key, e.target.value)
                    }
                    type={key === "emailId" ? "email" : "text"}
                    inputProps={{
                      style: {
                        textTransform: key === "emailId" ? "lowercase" : "none",
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "#f9f9f9",
                      },
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        {/* Education Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, backgroundColor: "#ffffff" }}>
            <Typography variant="h6" gutterBottom>
              Education
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Graduation Degree"
                  value={profileData.education.graduationDegree || ""}
                  onChange={(e) =>
                    handleChange(
                      "education",
                      "graduationDegree",
                      e.target.value
                    )
                  }
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "#f9f9f9",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  type="number"
                  label="10th Marks"
                  value={profileData.education.tenthBoardMarks.marks || ""}
                  onChange={(e) =>
                    handleNestedChange(
                      "education",
                      "tenthBoardMarks",
                      "marks",
                      e.target.value.slice(0, 3)
                    )
                  }
                  inputProps={{
                    maxLength: 3,
                    type: "number",
                    min: 0,
                    max: 999,
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "#f9f9f9",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  type="number"
                  label="10th Percentage"
                  value={profileData.education.tenthBoardMarks.percentage || ""}
                  onChange={(e) =>
                    handleNestedChange(
                      "education",
                      "tenthBoardMarks",
                      "percentage",
                      e.target.value.slice(0, 3)
                    )
                  }
                  inputProps={{
                    maxLength: 3,
                    type: "number",
                    min: 0,
                    max: 100,
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "#f9f9f9",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Family Information Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, backgroundColor: "#ffffff" }}>
            <Typography variant="h6" gutterBottom>
              Family Information
            </Typography>
            <Grid container spacing={2}>
              {Object.entries(profileData.familyInfo).map(([key, value]) => (
                <Grid item xs={12} sm={6} key={key}>
                  <TextField
                    required={key !== "parentsAnnualIncome"}
                    fullWidth
                    label={key
                      .charAt(0)
                      .toUpperCase() + key.slice(1).replace(/([A-Z])/g, " $1")}
                    value={value || ""}
                    onChange={(e) => {
                      if (key === "parentsAnnualIncome") {
                        // Ensure the annual income is not negative
                        const newValue = Math.max(
                          0,
                          parseInt(e.target.value, 10)
                        );
                        handleChange("familyInfo", key, newValue.toString());
                      } else {
                        handleChange("familyInfo", key, e.target.value);
                      }
                    }}
                    type={key === "parentsAnnualIncome" ? "number" : "text"}
                    inputProps={{
                      min: key === "parentsAnnualIncome" ? 0 : undefined,
                      step: key === "parentsAnnualIncome" ? 1 : undefined,
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        backgroundColor: "#f9f9f9",
                      },
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid> 

        {/* Future Goals Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, backgroundColor: "#ffffff" }}>
            <Typography variant="h6" gutterBottom>
              Future Goals
            </Typography>
            <TextField
              fullWidth
              label="5 Year Vision"
              value={profileData.futureGoals.fiveYearVision}
              onChange={(e) =>
                handleChange("futureGoals", "fiveYearVision", e.target.value)
              }
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "#f9f9f9",
                },
              }}
            />
          </Paper>
        </Grid>

        {/* Interests Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, backgroundColor: "#ffffff" }}>
            <Typography variant="h6" gutterBottom>
              Interests
            </Typography>
            <TextField
              fullWidth
              label="Hobbies"
              value={profileData.interests.hobbies.join(", ")}
              onChange={(e) =>
                handleChange(
                  "interests",
                  "hobbies",
                  e.target.value.split(", ").filter((hobby) => hobby.trim() !== "")
                )
              }
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "#f9f9f9",
                },
              }}
            />
          </Paper>
        </Grid>

        {/* Professional Information Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, backgroundColor: "#ffffff" }}>
            <Typography variant="h6" gutterBottom>
              Professional Information
            </Typography>
            <TextField
              fullWidth
              label="Post Applied For"
              value={profileData.professionalInfo.postAppliedFor}
              onChange={(e) =>
                handleChange(
                  "professionalInfo",
                  "postAppliedFor",
                  e.target.value
                )
              }
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "#f9f9f9",
                },
              }}
            />
          </Paper>
        </Grid>

        {/* Situational Judgment Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, backgroundColor: "#ffffff" }}>
            <Typography variant="h6" gutterBottom>
              Situational Judgment
            </Typography>
            <TextField
              fullWidth
              label="Late Work Scenario"
              value={profileData.situationalJudgment.lateWorkScenario}
              onChange={(e) =>
                handleChange(
                  "situationalJudgment",
                  "lateWorkScenario",
                  e.target.value
                )
              }
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "#f9f9f9",
                },
              }}
            />
          </Paper>
        </Grid>

        {/* Additional Information Section */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3, backgroundColor: "#ffffff" }}>
            <Typography variant="h6" gutterBottom>
              Additional Information
            </Typography>
            <TextField
              fullWidth
              label="Other Information"
              value={profileData.additionalInfo.otherInformation}
              onChange={(e) =>
                handleChange(
                  "additionalInfo",
                  "otherInformation",
                  e.target.value
                )
              }
              sx={{
                "& .MuiInputBase-root": {
                  backgroundColor: "#f9f9f9", // Light background for text fields
                },
              }}
            />
          </Paper>
        </Grid>
{/* Submit Button */}
<Grid item xs={12} sx={{ textAlign: "center", mt: 3 }}>
<Button
  variant="contained"
  sx={{
    backgroundColor: "#FFA500",
    color: "white",
    "&:hover": {
      backgroundColor: "#FF8C00",
    },
  }}
  onClick={handleSubmit}
  disabled={completionPercentage < 100 || loading}
>
  {loading ? (
    <CircularProgress size={24} color="inherit" />
  ) : (
    "Submit"
  )}
</Button>
</Grid>

<Snackbar
open={snackbarOpen}
autoHideDuration={6000}
onClose={() => setSnackbarOpen(false)}
>
<Alert
  onClose={() => setSnackbarOpen(false)}
  severity={snackbarSeverity}
  sx={{ width: "100%" }}
>
  {snackbarMessage}
</Alert>
</Snackbar>
</Grid>
</Container>
);
};

export default ProfileCompletion;
 