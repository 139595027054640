// import React from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
// import Login from "./components/Login";
// import Signup from "./components/Signup";
// import ResetPassword from "./components/ResetPassword";
// import Dashboard from "./components/Dashboard";
// import CVUpload from "./components/CVUpload";
// import ProfileCompletion from "./components/ProfileCompletion";
// import VirtualInterview from "./components/VirtualInterview";
// import AdminLogin from "./components/AdminLogin";
// import AdminDashboard from "./components/AdminDashboard";
// import { useAuth } from "./contexts/AuthContext";

// const theme = createTheme({
//   // Your theme configuration
// });
// console.log("Current environment:", process.env.REACT_APP_NODE_ENV);
// console.log("Current Dev environment", process.env.REACT_APP_API_BASE_URL_DEV);
// console.log(
//   "Current Prod environment:",
//   process.env.REACT_APP_API_BASE_URL_PROD
// );

// function App() {
//   const { tasks, token } = useAuth();

//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Router>
//         <Routes>
//           <Route
//             path="/"
//             element={
//               token ? (
//                 tasks?.["Uploading CV"] ? (
//                   tasks?.["Completing the Profile"] ? (
//                     <Navigate to="/dashboard" />
//                   ) : (
//                     <Navigate to="/profile-completion" />
//                   )
//                 ) : (
//                   <Navigate to="/cv-upload" />
//                 )
//               ) : (
//                 <Navigate to="/login" />
//               )
//             }
//           />
//           <Route path="/login" element={<Login />} />
//           <Route path="/signup" element={<Signup />} />
//           <Route path="/reset-password" element={<ResetPassword />} />
//           <Route
//             path="/dashboard"
//             element={token ? <Dashboard /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/cv-upload"
//             state={{}}
//             element={token ? <CVUpload /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/profile-completion"
//             element={token ? <ProfileCompletion /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/virtual-interview/:id"
//             element={token ? <VirtualInterview /> : <Navigate to="/login" />}
//           />
//           <Route path="/admin-login" element={<AdminLogin />} />

//           <Route path="/admin-dashboard" element={<AdminDashboard />} />
//         </Routes>
//       </Router>
//     </ThemeProvider>
//   );
// }

// export default App;
// import React from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
// import Login from "./components/Login";
// import Signup from "./components/Signup";
// import Dashboard from "./components/Dashboard";
// import CVUpload from "./components/CVUpload";
// import ProfileCompletion from "./components/ProfileCompletion";
// import VirtualInterview from "./components/VirtualInterview";
// import AdminLogin from "./components/AdminLogin";
// import AdminDashboard from "./components/AdminDashboard";
// import { useAuth } from "./contexts/AuthContext";

// // Import the new Reset Password components


// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#f47920', // Neptune Orange
//     },
//     secondary: {
//       main: '#006838', // Neptune Green
//     },
//   },
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           borderRadius: 4,
//           textTransform: 'none',
//           '&:focus': {
//             outline: '2px solid #f47920',
//           },
//         },
//       },
//     },
//     MuiTextField: {
//       styleOverrides: {
//         root: {
//           '& .MuiOutlinedInput-root': {
//             '& fieldset': {
//               borderColor: 'lightgray',
//             },
//             '&:hover fieldset': {
//               borderColor: '#006838',
//             },
//             '&.Mui-focused fieldset': {
//               borderColor: '#f47920',
//             },
//           },
//         },
//       },
//     },
//   },
// });

// console.log("Current environment:", process.env.REACT_APP_NODE_ENV);
// console.log("Current Dev environment", process.env.REACT_APP_API_BASE_URL_DEV);
// console.log(
//   "Current Prod environment:",
//   process.env.REACT_APP_API_BASE_URL_PROD
// );

// function App() {
//   const { tasks, token } = useAuth();

//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Router>
//         <Routes>
//           {/* Main Route with Authentication Check */}
//           <Route
//             path="/"
//             element={
//               token ? (
//                 tasks?.["Uploading CV"] ? (
//                   tasks?.["Completing the Profile"] ? (
//                     <Navigate to="/dashboard" />
//                   ) : (
//                     <Navigate to="/profile-completion" />
//                   )
//                 ) : (
//                   <Navigate to="/cv-upload" />
//                 )
//               ) : (
//                 <Navigate to="/login" />
//               )
//             }
//           />

//           {/* Public Routes */}
//           <Route path="/login" element={<Login />} />
//           <Route path="/signup" element={<Signup />} />
//           <Route path="/admin-login" element={<AdminLogin />} />

         

//           {/* Protected Routes */}
//           <Route
//             path="/dashboard"
//             element={token ? <Dashboard /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/cv-upload"
//             element={token ? <CVUpload /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/profile-completion"
//             element={token ? <ProfileCompletion /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/virtual-interview/:id"
//             element={token ? <VirtualInterview /> : <Navigate to="/login" />}
//           />
//           <Route 
//             path="/admin-dashboard" 
//             element={token ? <AdminDashboard /> : <Navigate to="/admin-login" />}
//           />
//         </Routes>
//       </Router>
//     </ThemeProvider>
//   );
// }

// export default App;
// import axios from 'axios';

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL || 'http://172.210.63.95:5000',
//   headers: {
//     'Content-Type': 'application/json',
//   }
// });

// // Add request interceptor for auth token
// api.interceptors.request.use((config) => {
//   // Check if it's an admin route
//   if (config.url.startsWith('/admin')) {
//     const adminToken = localStorage.getItem('adminToken');
//     if (adminToken) {
//       config.headers.Authorization = `Bearer ${adminToken}`;
//     }
//   } else {
//     const userToken = localStorage.getItem('token');
//     if (userToken) {
//       config.headers.Authorization = `Bearer ${userToken}`;
//     }
//   }
//   return config;
// });

// // Add response interceptor for error handling
// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.error('API Error:', error.response?.data || error.message);
//     return Promise.reject(error);
//   }
// );

// export default api;

// import React from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
// import Login from "./components/Login";
// import Signup from "./components/Signup";
// import ResetPassword from "./components/ResetPassword";
// import Dashboard from "./components/Dashboard";
// import CVUpload from "./components/CVUpload";
// import ProfileCompletion from "./components/ProfileCompletion";
// import VirtualInterview from "./components/VirtualInterview";
// import AdminLogin from "./components/AdminLogin";
// import AdminDashboard from "./components/AdminDashboard";
// import { useAuth } from "./contexts/AuthContext";

// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#ff8c00",
//     },
//     secondary: {
//       main: "#4caf50",
//     },
//   },
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           textTransform: "none",
//           borderRadius: 8,
//         },
//       },
//     },
//     MuiTextField: {
//       styleOverrides: {
//         root: {
//           "& .MuiOutlinedInput-root": {
//             "&:hover fieldset": {
//               borderColor: "#ff8c00",
//             },
//           },
//         },
//       },
//     },
//   },
// });

// console.log("Current environment:", process.env.REACT_APP_NODE_ENV);
// console.log("Current Dev environment", process.env.REACT_APP_API_BASE_URL_DEV);
// console.log("Current Prod environment:", process.env.REACT_APP_API_BASE_URL_PROD);

// function App() {
//   const { tasks, token } = useAuth();

//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Router>
//         <Routes>
//           {/* Home Route with Authentication Check */}
//           <Route
//             path="/"
//             element={
//               token ? (
//                 tasks?.["Uploading CV"] ? (
//                   tasks?.["Completing the Profile"] ? (
//                     <Navigate to="/dashboard" />
//                   ) : (
//                     <Navigate to="/profile-completion" />
//                   )
//                 ) : (
//                   <Navigate to="/cv-upload" />
//                 )
//               ) : (
//                 <Navigate to="/login" />
//               )
//             }
//           />

//           {/* Public Routes */}
//           <Route path="/login" element={<Login />} />
//           <Route path="/signup" element={<Signup />} />
//           <Route path="/reset-password" element={<ResetPassword />} />

//           {/* Protected Student Routes */}
//           <Route
//             path="/dashboard"
//             element={token ? <Dashboard /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/cv-upload"
//             element={token ? <CVUpload /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/profile-completion"
//             element={token ? <ProfileCompletion /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/virtual-interview/:id"
//             element={token ? <VirtualInterview /> : <Navigate to="/login" />}
//           />

//           {/* Admin Routes */}
//           <Route path="/admin-login" element={<AdminLogin />} />
//           <Route
//             path="/admin-dashboard"
//             element={
//               localStorage.getItem("adminToken") ? (
//                 <AdminDashboard />
//               ) : (
//                 <Navigate to="/admin-login" />
//               )
//             }
//           />

//           {/* Catch-all route - redirects to home */}
//           <Route path="*" element={<Navigate to="/" />} />
//         </Routes>
//       </Router>
//     </ThemeProvider>
//   );
// }

// export default App;

// import React from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
// } from "react-router-dom";
// import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
// import Login from "./components/Login";
// import Signup from "./components/Signup";
// import ResetPassword from "./components/ResetPassword";
// import Dashboard from "./components/Dashboard";
// import CVUpload from "./components/CVUpload";
// import ProfileCompletion from "./components/ProfileCompletion";
// import VirtualInterview from "./components/VirtualInterview";
// import AdminLogin from "./components/AdminLogin";
// import AdminDashboard from "./components/AdminDashboard";
// import { useAuth } from "./contexts/AuthContext";

// const theme = createTheme({
//   // Your theme configuration
// });

// function App() {
//   const { tasks, token } = useAuth();

//   return (
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <Router>
//         <Routes>
//           <Route
//             path="/"
//             element={
//               token ? (
//                 tasks?.["Uploading CV"] ? (
//                   tasks?.["Completing the Profile"] ? (
//                     <Navigate to="/dashboard" />
//                   ) : (
//                     <Navigate to="/profile-completion" />
//                   )
//                 ) : (
//                   <Navigate to="/cv-upload" />
//                 )
//               ) : (
//                 <Navigate to="/login" />
//               )
//             }
//           />
//           <Route path="/login" element={<Login />} />
//           <Route path="/signup" element={<Signup />} />
//           <Route path="/reset-password" element={<ResetPassword />} />
//           <Route
//             path="/dashboard"
//             element={token ? <Dashboard /> : <Navigate to="/login" />}
//           />
//           <Route
//             path="/cv-upload"
//             element={
//               token ? (
//                 tasks?.["Uploading CV"] ? (
//                   <Navigate to="/profile-completion" />
//                 ) : (
//                   <CVUpload />
//                 )
//               ) : (
//                 <Navigate to="/login" />
//               )
//             }
//           />
//           <Route
//             path="/profile-completion"
//             element={
//               token ? (
//                 !tasks?.["Uploading CV"] ? (
//                   <Navigate to="/cv-upload" />
//                 ) : (
//                   <ProfileCompletion />
//                 )
//               ) : (
//                 <Navigate to="/login" />
//               )
//             }
//           />
//           <Route
//             path="/virtual-interview/:id"
//             element={token ? <VirtualInterview /> : <Navigate to="/login" />}
//           />
//           <Route path="/admin-login" element={<AdminLogin />} />
//           <Route path="/admin-dashboard" element={<AdminDashboard />} />
//         </Routes>
//       </Router>
//     </ThemeProvider>
//   );
// }

// export default App;

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ResetPassword from "./components/ResetPassword";
import Dashboard from "./components/Dashboard";
import CVUpload from "./components/CVUpload";
import ProfileCompletion from "./components/ProfileCompletion";
import VirtualInterview from "./components/VirtualInterview";
import AdminLogin from "./components/AdminLogin";
import AdminDashboard from "./components/AdminDashboard";
import { useAuth } from "./contexts/AuthContext";

const theme = createTheme({
  // Your theme configuration
});

const App = () => {
  const { tasks, token } = useAuth();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              token ? (
                tasks?.["Uploading CV"] ? (
                  tasks?.["Completing the Profile"] ? (
                    <Navigate to="/dashboard" />
                  ) : (
                    <Navigate to="/profile-completion" />
                  )
                ) : (
                  <Navigate to="/cv-upload" />
                )
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route
            path="/dashboard"
            element={token ? <Dashboard /> : <Navigate to="/login" />}
          />
          <Route
            path="/cv-upload"
            state={{}}
            element={token ? <CVUpload /> : <Navigate to="/login" />}
          />
          <Route
            path="/profile-completion"
            element={token ? <ProfileCompletion /> : <Navigate to="/login" />}
          />
          <Route
            path="/virtual-interview/:id"
            element={token ? <VirtualInterview /> : <Navigate to="/login" />}
          />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;