// import React, { useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import {
//   Button,
//   TextField,
//   Typography,
//   Box,
//   Avatar,
//   Grid,
//   Snackbar,
//   Alert,
//   CircularProgress,
//   Container,
//   Paper,
// } from "@mui/material";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import { useAuth } from "../contexts/AuthContext";
// import logo from "../assets/logo.jpg";

// const Signup = () => {
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");
//   const [snackbarOpen, setSnackbarOpen] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState("");
//   const [snackbarSeverity, setSnackbarSeverity] = useState("success");
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();
//   const { signup } = useAuth();

//   const handleSnackbarClose = () => {
//     setSnackbarOpen(false);
//   };

//   const handleSignup = async (e) => {
//     e.preventDefault();

//     // Validate phone number
//     if (phoneNumber.trim().length !== 10 || !/^\d+$/.test(phoneNumber)) {
//       setSnackbarMessage("Please enter a valid 10-digit phone number");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);
//       return;
//     }

//     // Validate password
//     if (password.trim().length > 10) {
//       setSnackbarMessage("Password should be a maximum of 10 characters");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);
//       return;
//     }

//     if (password !== confirmPassword) {
//       setSnackbarMessage("Passwords don't match");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);
//       return;
//     }

//     setLoading(true);

//     try {
//       await signup(phoneNumber, password);
//       setSnackbarMessage("User created successfully!");
//       setSnackbarSeverity("success");
//       setSnackbarOpen(true);
//       navigate("/cv-upload");
//     } catch (err) {
//       setSnackbarMessage(err.message || "Something went wrong during signup");
//       setSnackbarSeverity("error");
//       setSnackbarOpen(true);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Container component="main" maxWidth="xs">
//       <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
//         <Box sx={{ textAlign: "center", mb: 2 }}>
//           <img
//             src={logo}
//             alt="Logo"
//             style={{ width: "100px", height: "auto" }}
//           />
//         </Box>
//         <Avatar sx={{ mx: "auto", bgcolor: "orange" }}>
//           <LockOutlinedIcon />
//         </Avatar>
//         <Typography
//           component="h1"
//           variant="h5"
//           align="center"
//           sx={{ color: "orange" }}
//         >
//           Sign Up
//         </Typography>
//         <Box component="form" onSubmit={handleSignup} noValidate sx={{ mt: 1 }}>
//           <TextField
//             margin="normal"
//             required
//             fullWidth
//             id="phone-number"
//             label="Phone Number"
//             name="phone-number"
//             autoComplete="tel"
//             autoFocus
//             value={phoneNumber}
//             onChange={(e) => setPhoneNumber(e.target.value)}
//             InputLabelProps={{
//               sx: {
//                 color: "lightgray",
//                 "&.Mui-focused": {
//                   color: "green",
//                 },
//               },
//             }}
//             sx={{
//               bgcolor: "#f9f9f9",
//               "& .MuiOutlinedInput-root": {
//                 "& fieldset": {
//                   borderColor: "lightgray",
//                 },
//                 "&:hover fieldset": {
//                   borderColor: "green",
//                 },
//                 "&.Mui-focused fieldset": {
//                   borderColor: "green",
//                 },
//               },
//             }}
//             inputProps={{ maxLength: 10 }}
//           />

//           <TextField
//             margin="normal"
//             required
//             fullWidth
//             name="password"
//             label="Password"
//             type="password"
//             id="password"
//             autoComplete="new-password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             InputLabelProps={{
//               sx: {
//                 color: "lightgray",
//                 "&.Mui-focused": {
//                   color: "green",
//                 },
//               },
//             }}
//             sx={{
//               bgcolor: "#f9f9f9",
//               "& .MuiOutlinedInput-root": {
//                 "& fieldset": {
//                   borderColor: "lightgray",
//                 },
//                 "&:hover fieldset": {
//                   borderColor: "green",
//                 },
//                 "&.Mui-focused fieldset": {
//                   borderColor: "green",
//                 },
//               },
//             }}
//             inputProps={{ maxLength: 10 }}
//           />

//           <TextField
//             margin="normal"
//             required
//             fullWidth
//             name="confirm-password"
//             label="Confirm Password"
//             type="password"
//             id="confirm-password"
//             autoComplete="new-password"
//             value={confirmPassword}
//             onChange={(e) => setConfirmPassword(e.target.value)}
//             InputLabelProps={{
//               sx: {
//                 color: "lightgray",
//                 "&.Mui-focused": {
//                   color: "green",
//                 },
//               },
//             }}
//             sx={{
//               bgcolor: "#f9f9f9",
//               "& .MuiOutlinedInput-root": {
//                 "& fieldset": {
//                   borderColor: "lightgray",
//                 },
//                 "&:hover fieldset": {
//                   borderColor: "green",
//                 },
//                 "&.Mui-focused fieldset": {
//                   borderColor: "green",
//                 },
//               },
//             }}
//             inputProps={{ maxLength: 10 }}
//           />

//           <Button
//             type="submit"
//             fullWidth
//             variant="contained"
//             sx={{
//               mt: 3,
//               mb: 2,
//               bgcolor: "orange",
//               "&:hover": { bgcolor: "#ff8c00" },
//               color: "white",
//             }}
//             disabled={loading}
//           >
//             {loading ? <CircularProgress size={24} /> : "Sign Up"}
//           </Button>
//           <Grid container justifyContent="flex-end">
//             <Grid item>
//               <Link
//                 to="/login"
//                 style={{ textDecoration: "none", color: "green" }}
//               >
//                 Already have an account? Sign in
//               </Link>
//             </Grid>
//           </Grid>
//         </Box>

//         <Snackbar
//           open={snackbarOpen}
//           autoHideDuration={6000}
//           onClose={handleSnackbarClose}
//         >
//           <Alert
//             onClose={handleSnackbarClose}
//             severity={snackbarSeverity}
//             sx={{ width: "100%" }}
//           >
//             {snackbarMessage}
//           </Alert>
//         </Snackbar>
//       </Paper>
//     </Container>
//   );
// };

// export default Signup;
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  TextField,
  Typography,
  Box,
  Avatar,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Container,
  Paper,
  InputAdornment,
  IconButton,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAuth } from "../contexts/AuthContext";
import logo from "../assets/logo.jpg";

const Signup = () => {
  const [formData, setFormData] = useState({
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { signup, error } = useAuth();

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    if (!phone) {
      return "Phone number is required";
    }
    if (!phoneRegex.test(phone)) {
      return "Phone number must be exactly 10 digits";
    }
    return "";
  };

  const validatePassword = (password) => {
    if (!password) {
      return "Password is required";
    }
    if (password.length > 10) {
      return "Password must not exceed 10 characters";
    }
    return "";
  };

  const validateConfirmPassword = (confirmPassword, password) => {
    if (!confirmPassword) {
      return "Please confirm your password";
    }
    if (confirmPassword !== password) {
      return "Passwords do not match";
    }
    return "";
  };

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    
    // Remove non-digit characters for phone number
    const newValue = field === 'phoneNumber' ? value.replace(/\D/g, '').slice(0, 10) : value;

    setFormData((prev) => ({
      ...prev,
      [field]: newValue,
    }));

    // Validate field on change
    let error = "";
    switch (field) {
      case "phoneNumber":
        error = validatePhoneNumber(newValue);
        break;
      case "password":
        error = validatePassword(newValue);
        // Also validate confirm password when password changes
        if (formData.confirmPassword) {
          setErrors((prev) => ({
            ...prev,
            confirmPassword: validateConfirmPassword(formData.confirmPassword, newValue),
          }));
        }
        break;
      case "confirmPassword":
        error = validateConfirmPassword(newValue, formData.password);
        break;
      default:
        break;
    }

    setErrors((prev) => ({
      ...prev,
      [field]: error,
    }));
  };

  const handleTogglePasswordVisibility = (field) => () => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    // Validate all fields
    const phoneNumberError = validatePhoneNumber(formData.phoneNumber);
    const passwordError = validatePassword(formData.password);
    const confirmPasswordError = validateConfirmPassword(
      formData.confirmPassword,
      formData.password
    );

    setErrors({
      phoneNumber: phoneNumberError,
      password: passwordError,
      confirmPassword: confirmPasswordError,
    });

    // Check if there are any errors
    if (phoneNumberError || passwordError || confirmPasswordError) {
      setSnackbarMessage("Please fix the errors before submitting");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    const result = await signup(formData.phoneNumber, formData.password);
    setLoading(false);

    if (result) {
      setSnackbarMessage("User created successfully!");
      setSnackbarSeverity("success");
      navigate("/cv-upload");
    } else if (error) {
      setSnackbarMessage(error);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const commonTextFieldProps = {
    fullWidth: true,
    margin: "normal",
    inputProps: {
      autoComplete: 'off',
      form: {
        autoComplete: 'off'
      }
    },
    sx: {
      backgroundColor: "#f9f9f9",
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'lightgray',
        },
        '&:hover fieldset': {
          borderColor: 'green',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'green',
        }
      },
      '& input::-moz-credentials-autofill-button': {
        display: 'none !important'
      },
      '& input::-moz-reveal': {
        display: 'none !important'
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ mt: 8, p: 4, backgroundColor: "#ffffff" }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "100px", height: "auto", marginBottom: "1rem" }}
          />
          
          <Avatar sx={{ bgcolor: "orange", mb: 1 }}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5" sx={{ color: "orange", mb: 3 }}>
            Sign Up
          </Typography>

          <Box 
            component="form" 
            onSubmit={handleSignup} 
            noValidate 
            autoComplete="off"
            sx={{ width: '100%' }}
          >
            <TextField
              {...commonTextFieldProps}
              required
              id="phone-number"
              name="phone-number"
              label="Phone Number"
              value={formData.phoneNumber}
              onChange={handleInputChange("phoneNumber")}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
              inputProps={{
                ...commonTextFieldProps.inputProps,
                maxLength: 10,
              }}
            />

            <TextField
              {...commonTextFieldProps}
              required
              name="new-password"
              label="Password"
              type={showPassword.password ? "text" : "password"}
              id="new-password"
              value={formData.password}
              onChange={handleInputChange("password")}
              error={!!errors.password}
              helperText={errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility("password")}
                      edge="end"
                    >
                      {showPassword.password ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              {...commonTextFieldProps}
              required
              name="confirm-new-password"
              label="Confirm Password"
              type={showPassword.confirmPassword ? "text" : "password"}
              id="confirm-new-password"
              value={formData.confirmPassword}
              onChange={handleInputChange("confirmPassword")}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility("confirmPassword")}
                      edge="end"
                    >
                      {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: "orange",
                "&:hover": { bgcolor: "#ff8c00" },
                color: "white",
                height: 48
              }}
            >
              {loading ? <CircularProgress size={24} /> : "Sign Up"}
            </Button>

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  to="/login"
                  style={{ textDecoration: "none", color: "green" }}
                >
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Signup;