// import React, { createContext, useContext, useState, useEffect } from "react";
// import api from "../api";

// const AuthContext = createContext();

// export const useAuth = () => {
//   const context = useContext(AuthContext);
//   if (!context) {
//     throw new Error("useAuth must be used within an AuthProvider");
//   }
//   return context;
// };

// export const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [token, setToken] = useState(() => localStorage.getItem("token"));
//   const [tasks, setTasks] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   // Clear error after 5 seconds
//   useEffect(() => {
//     if (error) {
//       const timer = setTimeout(() => setError(null), 5000);
//       return () => clearTimeout(timer);
//     }
//   }, [error]);

//   // Persist token in localStorage
//   useEffect(() => {
//     if (token) {
//       localStorage.setItem("token", token);
//     }
//   }, [token]);

//   const signup = async (phone, password) => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await api.post("/signup", {
//         phone: phone.trim(),
//         password,
//       });

//       if (response?.data?.access_token) {
//         setToken(response.data.access_token);
//         await fetchUserProfile(response.data.access_token);
//       }

//       return response;
//     } catch (err) {
//       const errorMessage = err.response?.data?.message || err.message || "Something went wrong during signup";
//       setError(errorMessage);
//       return null;
//     } finally {
//       setLoading(false);
//     }
//   };

//   const login = async (phone, password) => {
//     setLoading(true);
//     setError(null);
//     try {
//       const response = await api.post("/login", {
//         phone: phone.trim(),
//         password,
//       });

//       if (response?.data?.access_token) {
//         setToken(response.data.access_token);
//         await fetchUserProfile(response.data.access_token);
//       }

//       return response;
//     } catch (err) {
//       const errorMessage = err.response?.data?.message || err.message || "Invalid credentials";
//       setError(errorMessage);
//       return null;
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchUserProfile = async (accessToken) => {
//     try {
//       const profile = await api.get("/profile", {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//         },
//       });
//       setUser(profile.data.profile);
//       setTasks(profile.data.tasks);
//     } catch (err) {
//       console.error("Error fetching user profile:", err);
//     }
//   };

//   const logout = () => {
//     setUser(null);
//     setToken(null);
//     setTasks([]);
//     localStorage.removeItem("token");
//   };

//   return (
//     <AuthContext.Provider
//       value={{
//         user,
//         setUser,
//         token,
//         tasks,
//         setTasks,
//         signup,
//         login,
//         logout,
//         loading,
//         error,
//       }}
//     >
//       {children}
//     </AuthContext.Provider>
//   );
// };

import React, { createContext, useContext, useState, useEffect } from "react";
import api from "../api";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(null);
  const [tasks, setTasks] = useState({
    "Uploading CV": false,
    "Completing the Profile": false,
    "Starting the EQ test": false,
    "Submit EQ test": false
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Initialize auth state from localStorage
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      fetchUserProfile(storedToken);
    }
  }, []);

  const fetchUserProfile = async (authToken) => {
    try {
      const response = await api.get("/profile", {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setUser(response.data.profile);
      setTasks(response.data.tasks);
    } catch (err) {
      console.error("Error fetching user profile:", err);
      // Handle error - maybe logout if token is invalid
      logout();
    }
  };

  const signup = async (phone, password) => {
    setError(null);
    try {
      console.log("Signing up with phone:", phone, "and password:", password);
      const response = await api.post("/signup", { phone, password });
      
      console.log("Signup response:", response);

      // You might want to automatically log in the user after signup
      if (response.data.access_token) {
        setToken(response.data.access_token);
        localStorage.setItem("token", response.data.access_token);
        await fetchUserProfile(response.data.access_token);
      }

      return response;
    } catch (err) {
      setError(err.response?.data?.message || "Something went wrong during signup");
      return null;
    }
  };

  const login = async (phone, password) => {
    setError(null);
    try {
      const response = await api.post("/login", { phone, password });
      console.log("Login response:", response);

      if (response.data.access_token) {
        setToken(response.data.access_token);
        localStorage.setItem("token", response.data.access_token);
        
        const profile = await api.get("/profile", {
          headers: {
            Authorization: `Bearer ${response.data.access_token}`,
          },
        });

        setUser(profile.data.profile);
        setTasks(profile.data.tasks);
        return response;
      } else {
        throw new Error("No access token received");
      }
    } catch (err) {
      const errorMessage = err.response?.data?.message || "Invalid credentials";
      setError(errorMessage);
      return null;
    }
  };

  const logout = () => {
    setUser({});
    setToken(null);
    setTasks({
      "Uploading CV": false,
      "Completing the Profile": false,
      "Starting the EQ test": false,
      "Submit EQ test": false
    });
    localStorage.removeItem("token");
  };

  const updateTask = async (taskName, completed) => {
    try {
      // You might want to update the server about task completion
      // const response = await api.post("/update-task", { taskName, completed }, {
      //   headers: { Authorization: `Bearer ${token}` }
      // });

      setTasks(prevTasks => ({
        ...prevTasks,
        [taskName]: completed
      }));
    } catch (err) {
      console.error("Error updating task:", err);
      setError("Failed to update task status");
    }
  };

  const value = {
    user,
    setUser,
    token,
    setToken,
    tasks,
    setTasks,
    loading,
    error,
    signup,
    login,
    logout,
    updateTask,
    fetchUserProfile
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export default AuthContext;
